import {getCurrentUrl} from '@app/modules/location';
import {getSEOLocaleMetas} from '@app/modules/seo/locales';
import {DefaultSeo, NextSeo} from 'next-seo';
import {useRouter} from 'next/router';
import ogDeezerLogo from '@public/icons/logo-deezer-og.png';
import type {PageProps} from '@app/types/next';
import {useTranslation} from '@app/hooks/useTranslation';

export function SEO({seo}: {seo: PageProps['seo']}) {
	const {locale = 'en', asPath} = useRouter();
	const languageAlternates = getSEOLocaleMetas(asPath);
	const t = useTranslation();

	return (
		<>
			<DefaultSeo
				additionalMetaTags={[
					{
						name: 'viewport',
						content: 'initial-scale=1.0, width=device-width',
					},
				]}
				languageAlternates={languageAlternates}
				canonical={getCurrentUrl({locale, pathname: asPath})}
				openGraph={{
					url: getCurrentUrl({locale, pathname: asPath}),
					type: 'website',
					images: [
						{
							url: ogDeezerLogo.src,
							width: 200,
							height: 200,
							alt: 'Deezer Logo',
						},
					],
				}}
				twitter={{
					site: '@deezer',
					cardType: 'summary',
				}}
			/>
			<NextSeo
				title={seo?.title ? t(...seo.title) : ' '}
				description={seo?.description ? t(...seo.description) : ' '}
				openGraph={
					seo?.title &&
					seo?.description && {
						title: t(...seo.title),
						description: t(...seo.description),
					}
				}
			/>
		</>
	);
}
