import useSWRImmutable from 'swr/immutable';
import type {Client} from '@app/modules/oauth/types';
import {config} from '@app/modules/config';
import {usePathname, useRouter} from 'next/navigation';

export const extractClientFromPathname = (pathname: string) => {
	if (!pathname.startsWith('/oauth/')) return undefined;
	const clientId = pathname
		.replace('/oauth/', '')
		.split('/')
		.filter((e) => e.length > 0)
		.at(-1);
	return clientId?.match(/^[0-9a-z]+$/) ? clientId : undefined;
};

export const useClientId = () => {
	const pathname = usePathname();
	return extractClientFromPathname(pathname);
};

export const useClient = () => {
	const router = useRouter();
	const clientId = useClientId();

	function redirectTo404() {
		router.push('/404');
	}
	const {data: client, isLoading: isClientLoading} = useSWRImmutable(
		() =>
			clientId
				? `${config.get('oauthServiceUrl')}/api/client/${clientId}`
				: null,
		fetcher,
		{
			onSuccess(data) {
				if (!data) {
					redirectTo404();
				}
			},
			onError() {
				redirectTo404();
			},
		},
	);
	return {client, isClientLoading};
};

const headers = new Headers();
headers.append('x-api-key', config.get('publicAPIKey'));

async function fetcher(url: string): Promise<Client | undefined> {
	const res = await fetch(url, {headers});
	if (!res.ok) {
		return;
	}
	return await res.json();
}
