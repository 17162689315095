import {useRouter} from 'next/router';
import {
	getAppRedirectionConfig,
	isURLWhitelisted,
	NEXT_STEP_PARAM,
} from './config';
import type {ContextualizedAppRedirectionConfig} from './config';
import {appendStepsToRedirectURI, getRedirectUrlFromAppConfig} from './utils';
import {config} from '@app/modules/config';
import {useSearchParams} from 'next/navigation';
import {withQuery} from '@app/utils/utils';
import {useAppState} from '../apps/context';
import {useClientId} from '@app/hooks/useClient';
import {useCallback, useMemo, useState} from 'react';

export function useIsPaymentJourney() {
	const url = useRedirectionUrl();
	return url.includes(config.get('paymentHost')) || url.includes('/payment/');
}

/**
 * Returns the router push argument to redirect to (next_step, path or redirect_uri)
 */
export const useRedirectionUrl = (): string => {
	const {locale} = useRouter();
	const sp = useSearchParams();
	const redirectionConfig = useAppRedirectionConfig();
	const dangerous_nextParam = sp.getAll(NEXT_STEP_PARAM);
	const dangerous_redirectURI = sp.get(redirectionConfig.redirectParamName);

	if (dangerous_nextParam.length > 0) {
		const [dangerous_nextStep, ...followingSteps] = dangerous_nextParam;
		const search = new URLSearchParams(sp);
		search.delete(NEXT_STEP_PARAM);
		followingSteps.forEach((step) => search.append(NEXT_STEP_PARAM, step));
		return withQuery(
			redirectionConfig.redirectFormatter(dangerous_nextStep),
			search,
		);
	}

	/**
	 * Redirect back to existing referrer if we don't have a redirect
	 * param specified and if the referrer is whitelisted. This is
	 * especially useful for shared-dev envs to not end-up on devtest
	 * at the end of a journey started from your dev (or docker stack)
	 */
	if (
		!dangerous_redirectURI &&
		typeof document !== 'undefined' &&
		document.referrer &&
		!document.referrer.includes(location.hostname) &&
		isURLWhitelisted(document.referrer)
	) {
		return document.referrer;
	}

	const redirectParam = redirectionConfig.redirectFormatter(
		dangerous_redirectURI ?? '/',
	);

	/** Redirects to external redirect_uri */
	if (redirectParam?.startsWith('https://')) {
		// Make account urls relative
		if (redirectParam.includes(config.get('appUrl'))) {
			return redirectParam.replace(config.get('appUrl'), '');
		}
		return redirectParam;
	}

	/** Redirects to root */
	return getRedirectUrlFromAppConfig(
		redirectParam,
		redirectionConfig,
		locale ?? 'en',
	);
};

/**
 * Default redirection method to use. It will properly evaluate current URL state
 * and push user to the next step.
 */
export const useRedirection = () => {
	const {push} = useRouter();
	const [isRedirecting, setIsRedirecting] = useState(false);
	const redirectUri = useRedirectionUrl();
	const config = useAppRedirectionConfig();
	const query = useSearchParams();

	const redirectToUrl = useCallback(
		(nextSteps?: string[]) => {
			if (isRedirecting) return;
			setIsRedirecting(true);
			let finalRedirect = redirectUri;
			if (nextSteps) {
				finalRedirect = appendStepsToRedirectURI({
					redirectUri,
					intermediateSteps: nextSteps,
					config,
					query,
				});
			}
			push(finalRedirect);
		},
		[push, redirectUri, config, query, isRedirecting],
	);

	return redirectToUrl;
};

/**
 * Same as useRedirection but evaluates user state (and redirection config)
 * to automatically determine whether to add members picker to the next steps.
 */
export const useRedirectionAfterAuth = () => {
	const redirect = useRedirection();
	const appConfig = useAppRedirectionConfig();
	return useCallback(
		(nextSteps?: string[], canDisplayPicker?: boolean) => {
			// Display picker -> family selection activated for the app + family member connected
			const displayPicker =
				canDisplayPicker && appConfig.family?.shouldSelectSubProfile;

			let steps: string[] = [];

			if (displayPicker) {
				steps.push(appConfig.getAuthPaths().subProfilePicker);
			}
			if (nextSteps) {
				steps = [...steps, ...nextSteps];
			}

			return redirect(steps);
		},
		[appConfig, redirect],
	);
};

/**
 * Hook that provides the correct redirectionAppConfig depending on the global appState
 */
export const useAppRedirectionConfig =
	(): ContextualizedAppRedirectionConfig => {
		const {isOauth, appName} = useAppState();
		const clientId = useClientId();
		const {locale} = useRouter();
		return useMemo(
			() =>
				getAppRedirectionConfig({
					oauth: isOauth && clientId ? {clientId} : undefined,
					appName,
					locale,
				}),
			[isOauth, clientId, appName, locale],
		);
	};
