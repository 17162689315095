import {useApiCall} from '@deezer/react-legacy-api';
import type {LegacyApi} from '@deezer/react-legacy-api';
import useSWR, {type SWRConfiguration} from 'swr';

export function useSwrApi<Result = unknown>(
	params: Parameters<LegacyApi['call']>[0] | null,
	swrOptions?: Parameters<typeof useSWR>[2] & {
		cacheKey?: string | null;
		transform?: (result: any, error?: any) => Result;
	},
) {
	const apiCall = useApiCall();
	const cacheKey: string | null =
		swrOptions?.cacheKey !== undefined
			? swrOptions.cacheKey
			: params
				? JSON.stringify(params)
				: null;

	return useSWR(
		cacheKey,
		params
			? async function () {
					let error = null;
					const res = (await apiCall(params).catch((err) => {
						error = err;
					})) as Result;
					if (swrOptions?.transform) {
						return swrOptions.transform(res, error);
					} else if (error) {
						throw error;
					}
					return res;
				}
			: null,
		swrOptions as SWRConfiguration<Result>,
	);
}
