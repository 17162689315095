import {useAppState} from '@app/modules/apps/context';
import type {TranslationKey} from 'globalTranslations';
import {useTranslation as useT} from 'next-i18next';

export const useTranslation = () => {
	const {t} = useT();

	const {appName} = useAppState();
	const formalNameSpace = appName === 'Zen' ? 'formal' : 'regular';
	const translate = (
		key: TranslationKey,
		options?: {sprintf?: (string | number)[]; count?: number},
	) => {
		const [namespace, keyName] = key.split('.');
		return t(keyName, {
			ns: [namespace === 'formal' ? formalNameSpace : namespace, 'common'],
			...options,
		});
	};
	return translate;
};
export type TranslationParams = Parameters<ReturnType<typeof useTranslation>>;
