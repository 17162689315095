import dynamic from 'next/dynamic';

const ModalMapping = {
	action: dynamic(() => import('./Action')),
	error: dynamic(() => import('./Error')),
	msisdnConfirmation: dynamic(() => import('./ConfirmPhoneCode')),
	securedSessionEmailConfirmation: dynamic(
		() => import('./SecuredSessionModal/ConfirmEmailCode'),
	),
	securedSessionAskCode: dynamic(
		() => import('./SecuredSessionModal/RequestCode'),
	),
	missingInfo: dynamic(() => import('./MissingInfo')),
	revokePassword: dynamic(() => import('./RevokePassword')),
	upsell: dynamic(() => import('./Upsell')),
	verify_email: dynamic(() => import('./VerifyEmail')),
} as const;

export type ModalKey = keyof typeof ModalMapping;
export type ModalPropsMapping = {
	[K in ModalKey]: React.ComponentProps<(typeof ModalMapping)[K]>;
};

export function getModal(modal: ModalKey) {
	return ModalMapping[modal];
}
