export const withQuery = (url: string, search: URLSearchParams) => {
	if (search.toString().length) {
		return `${url}?${search.toString()}`;
	}
	return url;
};

export const createDeezerPageLink = (applink: string, noAppLink?: string) => {
	const baseUrl = new URL('https://deezer.page.link');

	// Deezer specific tracking param
	const targetUrl = new URL(applink);
	targetUrl.searchParams.set('bypassreferer', '1');

	// Default Query Params
	baseUrl.searchParams.set('apn', 'deezer.android.app');
	baseUrl.searchParams.set('amv', '6190600');
	baseUrl.searchParams.set('ibi', 'com.deezer.Deezer');
	baseUrl.searchParams.set('isi', '292738169');
	baseUrl.searchParams.set('imv', '7.11.0');

	// Behaviors Query Params
	baseUrl.searchParams.set('link', targetUrl.href);
	if (noAppLink) {
		const fallbackUrl = new URL(noAppLink);
		fallbackUrl.searchParams.set('bypassreferer', '1');

		baseUrl.searchParams.set('efr', '1');
		baseUrl.searchParams.set('ifl', fallbackUrl.href);
		baseUrl.searchParams.set('afl', fallbackUrl.href);
		baseUrl.searchParams.set('ofl', fallbackUrl.href);
	}
	return baseUrl.href;
};

export const getDateFromAgeFormat = (age: string | number, separator = '-') => {
	const newAge: string = typeof age === 'number' ? String(age) : age;
	const birthdayYear = new Date().getFullYear() - parseInt(newAge);
	const currentDay = new Date().getDate();
	const currentMonth = new Date().getMonth() + 1;

	return [birthdayYear, currentMonth, currentDay].join(separator);
};

export const createBdayFromAge = (age: number) => {
	return `${new Date().getFullYear() - age}-01-01`;
};
