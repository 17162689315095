import {getClientCookie, setClientCookie} from '@app/utils/cookies';

const UNIQ_ID_NAME = 'dzr_uniq_id';

function generateUniqueId() {
	const randomBytes = new Uint8Array(19);
	crypto.getRandomValues(randomBytes);
	const hexString = Array.prototype.map
		.call(randomBytes, (x) => ('00' + x.toString(16)).slice(-2))
		.join('');
	return `${UNIQ_ID_NAME}_fr${hexString}`;
}

export function getUniqId() {
	if (typeof window === 'undefined') return '';
	const cookie = getClientCookie(UNIQ_ID_NAME);
	if (cookie) return cookie;
	const generated = generateUniqueId();
	setClientCookie(UNIQ_ID_NAME, generated, {expires: 90});
	return generated;
}
