import React from 'react';
import {
	ColorModeScript,
	createLocalStorageManager,
	extendTheme,
	getZenExtension,
	TempoProvider,
} from '@tempo/core';
import type {PageProps} from '@app/types/next';
import {Epilogue} from 'next/font/google';
import {i18n} from 'next-i18next';
import {useRouter} from 'next/router';

const getDirection = (locale: string): 'rtl' | 'ltr' => {
	return i18n?.dir(locale) || 'ltr';
};

const epilogue = Epilogue({subsets: ['latin']});

type Extensions = Parameters<typeof extendTheme>;

const getThemeOverride = (templateName: string): Extensions => {
	templateName = templateName.toLowerCase();
	if (templateName === 'zen') {
		return [...getZenExtension({fontFamilyHeading: epilogue.style.fontFamily})];
	}
	return [];
};

const STORAGE_KEY = 'account-color-mode';
const localStorageManager = createLocalStorageManager(STORAGE_KEY);
const ThemeProvider: React.FC<
	{
		children?: React.ReactNode;
	} & Pick<PageProps, 'colorMode' | 'template'>
> = ({children, template, colorMode: defaultTheme}) => {
	const {locale} = useRouter();
	const direction = getDirection(locale ?? 'en');
	const theme = extendTheme(...getThemeOverride(template?.name ?? 'default'), {
		config: {
			direction,
			initialColorMode: defaultTheme || 'dark',
		},
	});

	React.useEffect(() => {
		document.querySelector('body')?.setAttribute('dir', direction);
	}, [direction]);
	return (
		<>
			<ColorModeScript
				initialColorMode={theme.config.initialColorMode}
				storageKey={STORAGE_KEY}
			/>
			<TempoProvider colorModeManager={localStorageManager} theme={theme}>
				{children}
			</TempoProvider>
		</>
	);
};

export default ThemeProvider;
