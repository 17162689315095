import type {BoxProps} from '@tempo/core';
import {Box} from '@tempo/core';

export const ZenLogo: React.FC<BoxProps> = (props) => {
	return (
		<Box
			as="svg"
			fill="none"
			height="32"
			viewBox="0 0 157 32"
			width="157"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g fill="#cb6623">
				<path d="m21.5924.639997h-20.56383v3.215243l14.13333-.58667-15.1619 28.22093h21.5924v-3.4743l-15.38288 1.021z" />
				<path d="m75.5277 8.60952c-.8991-6.81142-4.3658-8.60952-8.7391-8.60952-2.659 0-5.6305.944762-7.8857 3.56571l-.0838-3.184758-4.4952.388572c.1295 2.826666.1295 4.754286.1295 6.681906v19.01717l-.1295 5.0133h4.7542l-.1295-6.3009v-17.92005c1.4553-2.56 3.8781-4.18285 6.4229-4.18285 2.9562 0 4.8838 1.02857 5.3943 5.65333l2.8266 22.75047h4.8838l-2.9562-22.87238z" />
				<path d="m49.9734 12.4648c0-8.48004-4.2438-12.4648-12.7238-12.4648-10.1562 0-14.6515 7.96952-14.6515 15.5505 0 8.099 4.3657 16.4495 16.061 16.4495 3.7257 0 6.6819-.7695 9.6381-1.9276l-.64-4.1143c-2.5676 2.1867-5.6534 3.2152-8.3505 3.2152-8.0991 0-11.6952-5.6533-11.6952-14.5219 0-.5485.0152-1.0666.0457-1.5695zm-12.8534-9.50861c5.0134 0 7.9696 1.7981 8.0991 7.20001l-17.1353-.2362c1.1658-5.16571 4.4496-6.95619 9.0362-6.95619z" />
				<path d="m95.9852 19.9237c-.5612 0-1.0816-.1406-1.5613-.422-.4798-.2858-.8689-.676-1.1676-1.1706-.2942-.4946-.4413-1.055-.4413-1.6811 0-.0182 0-.0318 0-.0409 0-.0136 0-.0272 0-.0408l.8554-.0204v.034.0341c0 .372.095.7078.2851 1.0073.1946.2949.4457.5286.7535.701.3077.1724.6381.2586.9911.2586.5793 0 1.0658-.1974 1.4595-.5921.3982-.3993.5974-1.0119.5974-1.8377 0-.8303-.1969-1.4406-.5906-1.8308-.3937-.3948-.8825-.5921-1.4663-.5921-.353 0-.6834.0862-.9911.2586-.3078.1679-.5589.3993-.7535.6942-.1901.2904-.2851.6239-.2851 1.0005l-.8554-.034c0-.6307.1471-1.1865.4413-1.6675.2987-.4855.6878-.8644 1.1676-1.1366.4797-.2723 1.0001-.4084 1.5613-.4084.5838 0 1.1246.1452 1.6224.4356.4978.2859.8984.7056 1.2016 1.2591.3077.5536.4616 1.2274.4616 2.0214 0 .8122-.1494 1.4996-.448 2.0623-.2987.5626-.697.9891-1.1948 1.2795-.4978.2859-1.0454.4288-1.6428.4288zm-3.7947-.1429v-10.63794h1.4799v10.63794z" />
				<path d="m100.85 22.8571c-.195 0-.374-.0113-.536-.034-.159-.0181-.256-.0363-.292-.0544v-1.2047c.058.0272.156.0499.292.068.14.0182.285.0273.434.0273.267 0 .496-.0545.686-.1634.194-.1089.357-.2609.488-.456.136-.1906.245-.4084.326-.6534l.184-.5445-2.485-7.2893h1.534l1.853 6.1255h-.19l1.806-6.1255h1.514l-2.587 7.759c-.158.4855-.353.9188-.583 1.2999-.231.3857-.539.6897-.924.912-.384.2224-.891.3335-1.52.3335z" />
				<path d="m114.15 19.9237c-.592 0-1.138-.1429-1.636-.4288-.497-.2904-.896-.7169-1.194-1.2795-.299-.5627-.448-1.2501-.448-2.0623 0-.794.151-1.4678.454-2.0214.304-.5535.704-.9732 1.202-1.2591.498-.2904 1.039-.4356 1.622-.4356.562 0 1.082.1361 1.562.4084.484.2722.873.6511 1.167 1.1366.295.481.442 1.0368.442 1.6675v.0544.0409l-.856.0272c0-.0136 0-.0272 0-.0408 0-.0182 0-.0341 0-.0477 0-.3766-.095-.7101-.285-1.0005-.19-.2949-.441-.5263-.753-.6942-.308-.1724-.636-.2586-.985-.2586-.584 0-1.072.1973-1.466.5921-.394.3902-.591 1.0005-.591 1.8308 0 .8258.197 1.4384.591 1.8377.398.3947.887.5921 1.466.5921.362 0 .697-.0862 1.005-.2586.308-.177.554-.4175.74-.7215.185-.304.278-.6511.278-1.0413l.856.034c0 .6262-.143 1.1911-.428 1.6948-.281.5036-.661.9029-1.141 1.1978-.475.2904-1.009.4356-1.602.4356zm2.315-.1429v-10.63794h1.48v10.63794z" />
				<path d="m120.357 16.684v-1.1502h5.309l-.204.3403c0-.0272 0-.0545 0-.0817 0-.0317 0-.059 0-.0817 0-.3675-.079-.7055-.237-1.0141-.159-.313-.396-.5626-.713-.7486-.312-.1906-.704-.2859-1.175-.2859-.47 0-.884.1021-1.242.3063-.353.1996-.629.4855-.828.8575-.195.3676-.292.81-.292 1.3272 0 .5264.095.9801.285 1.3613.19.3766.464.667.822.8711.357.2042.785.3063 1.283.3063.348 0 .649-.034.902-.1021.258-.0726.469-.1679.632-.2858.167-.118.294-.2451.38-.3812.086-.1406.136-.279.149-.4152h1.467c-.028.295-.125.5876-.292.878-.163.2859-.396.5468-.7.7827-.298.2314-.665.4175-1.099.5581-.43.1362-.926.2042-1.487.2042-.751 0-1.41-.1588-1.975-.4764-.566-.3176-1.007-.7578-1.324-1.3204-.317-.5672-.475-1.216-.475-1.9465 0-.7442.16-1.3976.482-1.9602.321-.5626.767-1.0005 1.337-1.3136.57-.3176 1.224-.4764 1.962-.4764.751 0 1.401.1588 1.948.4764.552.3177.978.7555 1.276 1.3136.299.5581.448 1.2002.448 1.9261 0 .0862-.002.1861-.006.2995-.005.1134-.012.1906-.021.2314z" />
				<path d="m129.161 16.684v-1.1502h5.309l-.204.3403c0-.0272 0-.0545 0-.0817 0-.0317 0-.059 0-.0817 0-.3675-.079-.7055-.238-1.0141-.158-.313-.396-.5626-.712-.7486-.313-.1906-.704-.2859-1.175-.2859s-.885.1021-1.242.3063c-.353.1996-.629.4855-.828.8575-.195.3676-.292.81-.292 1.3272 0 .5264.095.9801.285 1.3613.19.3766.464.667.821.8711.358.2042.785.3063 1.283.3063.349 0 .65-.034.903-.1021.258-.0726.469-.1679.631-.2858.168-.118.295-.2451.381-.3812.086-.1406.135-.279.149-.4152h1.466c-.027.295-.124.5876-.292.878-.163.2859-.396.5468-.699.7827-.299.2314-.665.4175-1.1.5581-.43.1362-.925.2042-1.486.2042-.751 0-1.41-.1588-1.976-.4764-.565-.3176-1.007-.7578-1.323-1.3204-.317-.5672-.476-1.216-.476-1.9465 0-.7442.161-1.3976.482-1.9602.322-.5626.767-1.0005 1.338-1.3136.57-.3176 1.224-.4764 1.962-.4764.751 0 1.4.1588 1.948.4764.552.3177.977.7555 1.276 1.3136s.448 1.2002.448 1.9261c0 .0862-.002.1861-.007.2995-.004.1134-.011.1906-.02.2314z" />
				<path d="m138.257 18.5217h4.155v1.2591h-5.764v-1.2047l4.094-4.8119.02.0476h-3.958v-1.2591h5.54v1.2047l-4.066 4.7983z" />
				<path d="m144.13 16.684v-1.1502h5.308l-.203.3403c0-.0272 0-.0545 0-.0817 0-.0317 0-.059 0-.0817 0-.3675-.079-.7055-.238-1.0141-.158-.313-.396-.5626-.713-.7486-.312-.1906-.703-.2859-1.174-.2859s-.885.1021-1.242.3063c-.353.1996-.629.4855-.828.8575-.195.3676-.292.81-.292 1.3272 0 .5264.095.9801.285 1.3613.19.3766.464.667.821.8711.358.2042.785.3063 1.283.3063.349 0 .65-.034.903-.1021.258-.0726.468-.1679.631-.2858.168-.118.295-.2451.381-.3812.086-.1406.135-.279.149-.4152h1.466c-.027.295-.124.5876-.292.878-.163.2859-.396.5468-.699.7827-.299.2314-.665.4175-1.1.5581-.43.1362-.925.2042-1.486.2042-.752 0-1.41-.1588-1.976-.4764-.565-.3176-1.007-.7578-1.323-1.3204-.317-.5672-.476-1.216-.476-1.9465 0-.7442.161-1.3976.482-1.9602.322-.5626.767-1.0005 1.338-1.3136.57-.3176 1.224-.4764 1.961-.4764.752 0 1.401.1588 1.949.4764.552.3177.977.7555 1.276 1.3136s.448 1.2002.448 1.9261c0 .0862-.002.1861-.007.2995-.004.1134-.011.1906-.02.2314z" />
				<path d="m152.411 19.7808v-7.2281h1.473v2.3073l-.394-.177c.032-.2949.107-.5762.224-.8439.118-.2677.274-.506.469-.7147.199-.2132.443-.3811.733-.5036s.624-.1838 1.005-.1838c.289 0 .522.0227.699.0681.181.0453.292.0816.332.1089l-.312 1.3884c-.045-.0318-.138-.0726-.278-.1225-.136-.0499-.326-.0749-.57-.0749-.372 0-.679.0658-.924.1974-.244.1316-.439.3063-.583.5241-.145.2178-.249.456-.313.7146-.059.2541-.088.5105-.088.7691v3.7706z" />
			</g>
		</Box>
	);
};
