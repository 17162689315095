import type {RemoteClientV2} from '@deezer/logcenter';
import {RECLog, RECLogContext} from '@deezer/logcenter';
import type {AuthVitalsLog} from './type';
import type {AppName} from '@app/types/appName';

const recLogContext = new RECLogContext({
	name: 'user.auth_vitals',
	version: '1.0.0',
});

class AuthVitalsMetrics extends RECLog<
	AuthVitalsLog & RemoteClientV2 & {country?: string}
> {
	#journey_type: AuthVitalsLog['journey_type'];
	#error: string;
	#app_name: AppName;
	#error_extra?: string;
	#gateway_method?: string;
	#device_uniq_id: string;
	#server_payload?: string;
	#country?: string;

	constructor(params: AuthVitalsLog) {
		super({
			type: 'user.auth_vitals',
			version: '1.0.0',
			...recLogContext,
		});

		this.#journey_type = params.journey_type;
		this.#error = params.error;
		this.#app_name = params.app_name;
		this.#error_extra = params.error_extra;
		this.#gateway_method = params.gateway_method;
		this.#device_uniq_id = params.device_uniq_id;
		this.#server_payload = params.server_payload;
		this.#country = params.country;
	}

	serialize() {
		return {
			...super.serialize(),
			...(this.#error_extra && {error_extra: this.#error_extra}),
			gateway_method: this.#gateway_method,
			device_uniq_id: this.#device_uniq_id,
			journey_type: this.#journey_type,
			server_payload: this.#server_payload,
			country: this.#country,
			error: this.#error,
			platform: {
				...this.platform,
				app: {
					...this.platform.app,
					name: this.#app_name.toLocaleLowerCase(),
				},
			},
		};
	}
}

export default AuthVitalsMetrics;
