import React from 'react';
import {DeezerLogo, TopBar, TopBarLogo} from '@tempo/core';
import {ZenLogo} from '@app/components/Logos';
import type {AppName} from '@app/types/appName';
import {useAppState} from '@app/modules/apps/context';
import {useLocation} from '@app/hooks/useLocation';

const AppLogo: Record<AppName, React.ReactNode> = {
	Deezer: <DeezerLogo height="30px" id="topbar-deezer-logo" />,
	Shaker: (
		<DeezerLogo height={{base: '22px', md: '28px'}} id="topbar-deezer-logo" />
	),
	Games: (
		<DeezerLogo height={{base: '22px', md: '28px'}} id="topbar-deezer-logo" />
	),
	Zen: <ZenLogo height={{base: '22px', md: '28px'}} id="topbar-zen-logo" />,
};

export const CobrandedTopbar: React.FC = () => {
	const {appName} = useAppState();
	const {getDeezerUrl} = useLocation();
	return (
		<TopBar
			px={{base: 'spacing.s', sm: '80px', md: '112px', lg: '192px'}}
			py="spacing.xl"
			borderBottom={{base: 'none', sm: 'solid 1px'}}
			borderBottomColor="divider.neutral.primary.default !important"
		>
			<TopBarLogo
				width="100%"
				justifyContent={appName === 'Deezer' ? 'left' : 'center'}
				me="0"
			>
				<a href={getDeezerUrl('/')}>{AppLogo[appName]}</a>
			</TopBarLogo>
		</TopBar>
	);
};
