import {
	Modal,
	useDisclosure,
	ModalOverlay,
	ModalCloseButton,
	ModalContent,
} from '@tempo/core';
import {type ComponentType, useState, useCallback} from 'react';
import {
	ModalControllerProvider,
	type ModalControllerProps,
	type ModalState,
} from './state';
import {type ModalPropsMapping, getModal} from './List';

export function ModalController({children}: React.PropsWithChildren) {
	const [{current, modalOptions, props}, setCurrentModal] =
		useState<ModalState>({current: null, modalOptions: {}, props: null});
	const {onOpen, isOpen, onClose} = useDisclosure();

	const handleClose = useCallback(() => {
		setCurrentModal({current: null, modalOptions: {}, props: null});
		modalOptions?.onClose?.();
		onClose();
	}, [onClose, modalOptions]);

	const handleOpenModal: ModalControllerProps['openModal'] = useCallback(
		(modal, props = null, modalOptions = {}) => {
			const defaultOptions = {size: 'md', isCentered: true};
			if (
				current === 'error' &&
				props &&
				(props as ModalPropsMapping['error']).title.length > 30
			) {
				defaultOptions.size = 'xl';
			}
			const finalOptions = {
				...defaultOptions,
				...modalOptions,
			};
			setCurrentModal({modalOptions: finalOptions, props, current: modal});
			onOpen();
		},
		[current, onOpen],
	);

	function ModalContentBody() {
		if (!current) {
			return null;
		}
		const CurrentModal = getModal(current) as ComponentType<
			ModalPropsMapping[typeof current]
		>;
		return (
			<ModalContent mx="spacing.xl">
				<ModalCloseButton data-testid="close-modal-btn" />
				<CurrentModal {...(props as ModalPropsMapping[typeof current])} />
			</ModalContent>
		);
	}

	return (
		<ModalControllerProvider
			value={{closeModal: handleClose, openModal: handleOpenModal, current}}
		>
			{children}
			<Modal {...modalOptions} isOpen={isOpen} onClose={handleClose}>
				<ModalOverlay />
				<ModalContentBody />
			</Modal>
		</ModalControllerProvider>
	);
}
