import {useSwrApi} from '@app/modules/api/swr-legacy-api';
import {useAutolog} from './auth/useAutolog';
import {AuthStatus} from './auth/useAutolog/utils';
import {useAppState} from '@app/modules/apps/context';

export function useClientCountry() {
	const {status} = useAutolog();
	const {appName} = useAppState();
	const {data, mutate, ...rest} = useSwrApi(
		{
			method: 'deezer.getCountry',
		},
		{
			cacheKey:
				status === AuthStatus.loading ? null : `${appName}-user-country-data`,
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		},
	);

	return {
		country: data as string,
		refetchClientCountry: mutate,
		...rest,
	};
}
