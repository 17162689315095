import type {AuthBaseConstructorOptions} from '@deezer/auth';
import {
	AuthBase,
	QUERY_PARAMS_TYPE,
	WithAnonymousLogin,
	WithArlLogin,
} from '@deezer/auth';
import {config} from '@app/modules/config';

const Auth = WithAnonymousLogin(WithArlLogin(AuthBase));

export const buildAuthInstance = (
	server = typeof window === 'undefined',
	options?: Omit<AuthBaseConstructorOptions, 'host'>,
) => {
	if (server) {
		return new Auth({
			host: config.get('internalAuthHost'),
			refreshTokenOutputType: QUERY_PARAMS_TYPE.PAYLOAD,
			tokenOutputType: QUERY_PARAMS_TYPE.PAYLOAD,
			...options,
		});
	}
	return new Auth({
		host: config.get('authHost'),
		tokenOutputType: QUERY_PARAMS_TYPE.PAYLOAD,
		...options,
	});
};
