export function parseImageMd5toUrl(
	domain: string,
	md5: string,
	width = 80,
	height = 80,
	color = '000000',
	dir = 'misc',
): string {
	const ext = color === 'none' ? 'png' : 'jpg';
	return `${domain}/${dir}/${md5}/${height}x${width}-${color}-90-1-1.${ext}`;
}
