import React from 'react';
import Link from 'next/link';
import type {LinkProps} from 'next/link';
import type {ButtonProps} from '@tempo/core';
import {Button} from '@tempo/core';

export type NavLinkProps = ButtonProps &
	Omit<LinkProps, 'onClick'> & {target?: string};

export const NavLink: React.FC<NavLinkProps> = (props) => {
	return (
		<Button
			color="background.accent.primary.default"
			_active={{color: 'background.accent.primary.pressed'}}
			as={Link}
			fontWeight="bold"
			variant="link"
			textTransform="none"
			{...props}
		/>
	);
};
