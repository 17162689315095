import {useAppState} from '@app/modules/apps/context';
import {config} from '@app/modules/config';
import {useEffect, useState} from 'react';
import {logCenter} from '../logCenter';
import type {PageConfig} from '@app/types/next';
import {useClientId} from '@app/hooks/useClient';
import {useAuthConfig} from '@app/hooks/auth/useAuthConfig';
import {useRouter} from 'next/router';

type TrackingContext = {
	location: Location;
	appName: PageConfig['appName'];
	isOauth: PageConfig['isOauth'];
	clientId: string | undefined;
	payPrefix: boolean | undefined;
	redirectUriParam: string | null;
	isSmartJourney: boolean;
};

const useLogContext = (): TrackingContext | null => {
	const {data: authConfig} = useAuthConfig();
	const {appName, isOauth} = useAppState();
	const [context, setContext] = useState<TrackingContext | null>(null);
	const clientId = useClientId();
	const {asPath} = useRouter();

	useEffect(() => {
		if (!authConfig) return;
		const redirectUriParam = new URLSearchParams(location.search).get(
			'redirect_uri',
		);
		const pathParam = new URLSearchParams(location.search).get('path');
		const payPrefix =
			pathParam?.includes(config.get('paymentHost')) ||
			redirectUriParam?.includes(config.get('paymentHost')) ||
			pathParam?.includes('/payment/') ||
			redirectUriParam?.includes('/payment/');

		setContext({
			location,
			appName,
			isOauth,
			clientId,
			payPrefix,
			redirectUriParam,
			isSmartJourney: authConfig.default_journeys.register === 'smart',
		});
	}, [authConfig, asPath]);

	return context;
};

export const logLoginPage = (trackingContext: TrackingContext) => {
	if (
		trackingContext.appName === 'Deezer' &&
		trackingContext.location.pathname.includes('login')
	) {
		logCenter.log({
			type: 'cdp',
			eventName: 'screen_view',
			customAttributes: {
				screen_view_name: trackingContext.payPrefix
					? 'unlogged_pay_login'
					: 'unlogged_login',
			},
		});
	}
};

export const logRegisterPage = (trackingContext: TrackingContext) => {
	if (
		trackingContext.appName === 'Deezer' &&
		trackingContext.location.pathname.includes('signup') &&
		!trackingContext.isSmartJourney
	) {
		logCenter.log({
			type: 'cdp',
			eventName: 'screen_view',
			customAttributes: {
				screen_view_name: trackingContext.payPrefix
					? 'unlogged_pay_register'
					: 'unlogged_register',
			},
		});
	}
};

export const logOauthLoginPage = (trackingContext: TrackingContext) => {
	if (
		trackingContext.isOauth &&
		trackingContext.location.pathname.includes('login')
	) {
		logCenter.log({
			type: 'cdp',
			eventName: 'screen_view',
			customAttributes: {
				screen_view_name: 'oauth_login_deezer',
				screen_view_id: trackingContext.clientId,
			},
		});
	}
};

export const logOauthRegisterPage = (trackingContext: TrackingContext) => {
	if (
		trackingContext.isOauth &&
		trackingContext.location.pathname.includes('signup')
	) {
		logCenter.log({
			type: 'cdp',
			eventName: 'screen_view',
			customAttributes: {
				screen_view_name: 'oauth_sign_up_deezer',
				screen_view_id: trackingContext.clientId,
			},
		});
	}
};

export const logSmartJourney = ({
	appName,
	stepName,
}: {
	appName: PageConfig['appName'];
	stepName?: string;
}) => {
	if (!appName || !stepName) return;
	const screen_view_id =
		appName === 'Games'
			? 'music_quizz'
			: appName === 'Shaker'
				? 'music_together'
				: 'deezer';

	const defauldPayload = {
		screen_view_id,
		screen_view_id_type: 'page',
	};

	const getPayload = () => {
		switch (stepName) {
			case 'email_or_phone':
				return {
					...defauldPayload,
					screen_view_name: 'smart_journey_register_email',
				};

			case 'login_email_autolog':
				return {
					...defauldPayload,
					screen_view_name: 'autolog_mail_sent_confirm',
				};

			case 'verify_email':
				return {
					...defauldPayload,
					screen_view_name: 'unlogged_verify_email',
				};

			case 'user_profile':
				return {
					...defauldPayload,
					screen_view_name: 'unlogged_register_personal_info',
				};

			case 'username_and_picture':
				return {
					...defauldPayload,
					screen_view_name: 'smart_journey_register_profile',
				};

			default:
				return null;
		}
	};

	if (getPayload()) {
		logCenter.log({
			type: 'cdp',
			eventName: 'screen_view',
			customAttributes: {
				...getPayload(),
			},
		});
	}
};

export function useCDPPageView() {
	const trackingContext = useLogContext();
	useEffect(() => {
		if (!trackingContext) return;
		logRegisterPage(trackingContext);
		logLoginPage(trackingContext);
		logOauthLoginPage(trackingContext);
		logOauthRegisterPage(trackingContext);
	}, [trackingContext]);
}
