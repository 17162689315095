import React from 'react';
import {
	Box,
	DeezerLogo,
	Link,
	Stack,
	TopBar,
	TopBarActions,
	TopBarList,
	TopBarLogo,
} from '@tempo/core';
import {NavLink} from '@app/components/Navlink';
import {useLocation} from '@app/hooks/useLocation';
import dynamic from 'next/dynamic';
import type {Client} from '@app/modules/oauth/types';
import {ClientLogo} from '@app/components/Layouts/elements/ClientLogo';
import {useAppState} from '@app/modules/apps/context';

const WrappedAvatar = dynamic(() => import('./WrappedAvatar'));

type TopbarConfig = {
	links: {url: string; name: string; tracking: string}[];
};
const useTopbarConfig = (): readonly [TopbarConfig] => {
	return [{links: []}];
};

export type TopbarProps = {
	client?: Client;
};

export const Topbar: React.FC<TopbarProps> = ({client}) => {
	const {isOauth} = useAppState();
	const {getDeezerUrl} = useLocation();
	const [config] = useTopbarConfig();
	const hasClientLogo =
		client?.options.logoThemeDarkUrl && client?.options.logoThemeLightUrl;
	const bgProps = !hasClientLogo ? {bg: 'white', _dark: {bg: 'black'}} : {};
	const hasDeezerLink = !hasClientLogo && !isOauth;

	return (
		<TopBar px={{base: 4, md: 24}} {...bgProps} py={{base: 3, md: 5}}>
			<TopBarLogo>
				<Stack
					as={Link}
					href={hasDeezerLink ? getDeezerUrl('/') : undefined}
					pointerEvents={hasDeezerLink ? 'auto' : 'none'}
					alignItems="center"
					direction="row"
					spacing="spacing.l"
				>
					<DeezerLogo
						height={{base: '22px', md: '28px'}}
						data-testid="deezer-logo"
					/>
					{hasClientLogo && (
						<>
							<Box
								height={{base: '22px', md: '28px'}}
								width="1px"
								bg="border.neutral.primary.default"
							/>
							<ClientLogo client={client} />
						</>
					)}
				</Stack>
			</TopBarLogo>
			{config.links.map(({url, name}) => (
				<NavLink key={name} fontWeight="semibold" href={url}>
					{name}
				</NavLink>
			))}
			<TopBarList alignItems="center"></TopBarList>
			<TopBarActions>
				<WrappedAvatar />
			</TopBarActions>
		</TopBar>
	);
};
