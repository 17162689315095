// Modules
import {useCallback, useMemo} from 'react';
import {useRouter} from 'next/router';

// Config
import {config} from '@app/modules/config';
import {getLegacyLocaleFromLocale} from '@deezer/deezer-compatibility';

type GetCurrentUrl = (options?: GetCurrentUrlOptions) => string;
type GetDeezerUrl = (
	path?: string,
	options?: Partial<GetDeezerUrlOptions>,
) => string;

type GetCurrentUrlOptions = {
	withQuery?: boolean;
	withLocale?: boolean;
};

type GetDeezerUrlOptions = {
	withLocale: boolean;
};

export const useLocation = () => {
	const router = useRouter();

	const getCurrentUrl: GetCurrentUrl = useCallback(
		(options = {}) => {
			const currentUrl = new URL(config.get('appUrl'));
			currentUrl.pathname = options.withLocale
				? `/${router.locale}${router.asPath}`
				: router.asPath;
			return options.withQuery
				? currentUrl.href
				: currentUrl.href.replace(currentUrl.search, '');
		},
		[router.asPath, router.locale],
	);

	const getDeezerUrl: GetDeezerUrl = useCallback(
		(
			path = '/',
			options: Partial<GetDeezerUrlOptions> = {withLocale: true},
		) => {
			if (!options.withLocale) {
				return `${config.get('deezerWebsiteUrl')}${path}`;
			}
			const country = getLegacyLocaleFromLocale(router.locale ?? '');
			return `${config.get('deezerWebsiteUrl')}/${country}${path}`;
		},
		[router.locale],
	);

	const currentPage = useMemo(
		() => router.asPath.replace(/^\/+/, '').replace(/\/+$/, ''),
		[router.asPath],
	);

	return {
		getCurrentUrl,
		getDeezerUrl,
		currentPage,
	};
};
