import {getCurrentUrl} from '../location';
import metas from 'locales/metas.json';

type LanguageAlternate = {
	hrefLang: string;
	href: string;
};

const SEO_DEFAULT_LOCALE = 'en';

export const getSEOLocaleMetas = (
	pathname: string,
	seoLocales = metas.availableLanguages,
): LanguageAlternate[] => {
	const filteredLocales = seoLocales?.filter(
		(lang) => !metas.sunsetLanguages.includes(lang) && lang !== 'default',
	);
	if (filteredLocales.length === 0) {
		throw new Error('Cannot have no opened locales');
	}
	return [
		{
			hrefLang: 'x-default',
			href: filteredLocales.includes(SEO_DEFAULT_LOCALE)
				? getCurrentUrl({locale: SEO_DEFAULT_LOCALE, pathname})
				: getCurrentUrl({locale: filteredLocales[0], pathname}),
		},
		...filteredLocales.map((locale) => ({
			href: getCurrentUrl({locale, pathname}),
			hrefLang: locale,
		})),
	];
};
