import {useEffect} from 'react';
import {setRECToken} from '../logCenter';
import {usePageView} from '../gtm/pageview';
import {logAttributionContext} from '../attribution_context';
import {useCDPPageView} from '../cdp/pageviews';
import {useInitCDP} from '../cdp/init';
import {useUserData} from '@app/hooks/controllers/useUserData';

/** Module that setup all tracking utils */
export const Tracking = () => {
	const {user} = useUserData();
	usePageView();
	useInitCDP();
	useCDPPageView();
	useEffect(() => {
		if (!user) return;
		setRECToken(user.userToken ?? '');
		logAttributionContext(user?.id.toString());
	}, [user]);

	return null;
};
