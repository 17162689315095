import {useClient} from '@app/hooks/useClient';
import {useAppState} from './context';
import type {AppName} from '@app/types/appName';

const UNKNOWN_APP_ID = 0;
const AppNameToAppIdMapping: Record<AppName, number> = {
	Deezer: 632384,
	Games: UNKNOWN_APP_ID,
	Shaker: 632584,
	Zen: UNKNOWN_APP_ID,
};

export function useAppId() {
	const {appName} = useAppState();
	const {client, isClientLoading} = useClient();

	return {
		isLoading: isClientLoading,
		appId: isClientLoading
			? undefined
			: (client?.appId ?? AppNameToAppIdMapping[appName]),
	};
}
