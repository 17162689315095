import LogCenter, {
	RECSender,
	CDPSender,
	BatchStrategy,
	DirectStrategy,
	ConsoleSender,
	MemoryStorage,
	getBatchConfiguration,
	GateStrategy,
	GTMSender,
} from '@deezer/logcenter';
import {config} from '@app/modules/config';
import {getCDP} from '../cdp';

const memoryStorage = new MemoryStorage();

const batchConfiguration = getBatchConfiguration({
	maxBatchSize: 10,
	autoSendInterval: 5 * 1000,
});

let needsFlush = false;
let canFlush = false;
export function setRECToken(token: string) {
	if (!token) return;
	recSender.setAuthToken(token);
	canFlush = true;
	if (needsFlush) {
		logCenter.flush();
		needsFlush = false;
	}
}

const recSender = new RECSender(
	`https://${config.get('recHost')}/1.0/events/`,
	'',
	batchConfiguration,
);

recSender.setAuthToken(config.get('recTokenUnlogged'));

export const cdpSender = new CDPSender(getCDP());
export const gtmSender = new GTMSender();

const consoleSender = new ConsoleSender();
const consoleStrategy = new DirectStrategy(consoleSender, memoryStorage, {
	handledLogTypes: process.env.NODE_ENV === 'development' ? ['debug'] : [],
});
const cdpConfiguration = {
	handledLogTypes: ['cdp'],
};

export const cdpStrategy = new GateStrategy(
	cdpSender,
	memoryStorage,
	cdpConfiguration,
);

export const recStrategy = new BatchStrategy(recSender, memoryStorage, {
	...batchConfiguration,
	ignoredLogTypes: ['cdp', 'debug', 'gtm'],
});

export const gtmStrategy = new GateStrategy(gtmSender, memoryStorage, {
	handledLogTypes: ['gtm'],
});

export const logCenterInstance = new LogCenter(
	[recStrategy, cdpStrategy, consoleStrategy, gtmStrategy],
	memoryStorage,
);

export const logCenter = {
	log: logCenterInstance.log.bind(logCenterInstance),
	flush: function () {
		if (canFlush) {
			recStrategy.flush();
		} else {
			// Delay flush 'till when the token has been set
			needsFlush = true;
		}
	},
};
