import type {ApiGetUserChildAccount, UserData} from '@app/types/userData';
import {parseImageMd5toUrl} from './images';
import type {ApiGetUserData, Child} from '@deezer/react-user';
import {config} from '@app/modules/config';

export function transformLegacyUser(
	apiGetUserData: ApiGetUserData & {OFFER_NAME?: string},
): UserData {
	const familyConfig = !Array.isArray(apiGetUserData.USER?.MULTI_ACCOUNT)
		? apiGetUserData.USER?.MULTI_ACCOUNT
		: undefined;
	const user: UserData = {
		country: apiGetUserData.COUNTRY,
		direction: apiGetUserData.DIRECTION || 'ltr',
		lang: apiGetUserData.SETTING_LANG || 'en',
		locale: apiGetUserData.SETTING_LOCALE || 'en-GB',
		sid: apiGetUserData?.SESSION_ID,
		referer: apiGetUserData?.SETTING_REFERER_UPLOAD,
		settings: {
			isDarkModeOn: apiGetUserData.USER?.SETTING?.global?.dark_mode === 'on',
		},
		id: apiGetUserData?.USER?.USER_ID ?? 0,
		email: apiGetUserData.USER?.EMAIL,
		blogName: apiGetUserData.USER?.BLOG_NAME,
		pictureMD5: apiGetUserData.USER?.USER_PICTURE,
		offerId: apiGetUserData?.OFFER_ID,
		offerName: apiGetUserData?.OFFER_NAME,
		userAge: apiGetUserData.USER?.USER_AGE,
		userToken: apiGetUserData.USER_TOKEN,
		getPictureUrl: (
			width?: number,
			height?: number,
			color?: string,
			dir = 'misc',
		) =>
			parseImageMd5toUrl(
				config.get('deezerImagesUrl'),
				apiGetUserData.USER?.USER_PICTURE ?? '',
				width,
				height,
				color,
				dir,
			),
		familyStatus: familyConfig
			? {
					isActive: familyConfig.ACTIVE,
					isEnabled: familyConfig.ENABLED,
					childCount: familyConfig.CHILD_COUNT ?? 0,
					parent: familyConfig.PARENT
						? {
								id: familyConfig.PARENT.id,
								blogName: familyConfig.PARENT.blog_name,
								email: familyConfig.PARENT.email,
							}
						: null,
					isKid: familyConfig.IS_KID,
					remainingAccount:
						(familyConfig.MAX_CHILDREN ?? 0) - (familyConfig.CHILD_COUNT ?? 0),
					isMainAccount: familyConfig.PARENT === null,
					isSubAccount: familyConfig.IS_SUB_ACCOUNT,
					maxChildren: familyConfig.MAX_CHILDREN ?? 0,
				}
			: {
					isActive: false,
					isEnabled: false,
					childCount: 0,
					parent: null,
					isKid: false,
					isMainAccount: true,
					isSubAccount: false,
					maxChildren: 0,
					remainingAccount: 0,
				},
	};
	return user;
}

export function transformLegacyChild(
	child: ApiGetUserChildAccount[0],
	pictureDomain: string,
): Child {
	return {
		id: child.USER_ID,
		blogname: child.BLOG_NAME,
		isKid: child.IS_KID,
		isMainAccount: child.PARENT_ID === null,
		caption: child.EXTRA_FAMILY.CAPTION,
		permissions: {
			isLoggableAs: child.EXTRA_FAMILY.IS_LOGGABLE_AS,
			isPersonnalDataEditable: child.EXTRA_FAMILY.IS_PERSONAL_DATA_EDITABLE,
			isExplicitLabelEditable: child.EXTRA_FAMILY.IS_EXPLICIT_LEVEL_EDITABLE,
			forceExplicitLevel: child.EXTRA_FAMILY.TOGGLE_EXPLICIT_CONTROL,
			isDeletable: child.EXTRA_FAMILY.IS_DELETABLE,
			isDelinkable: child.EXTRA_FAMILY.IS_DELINKABLE,
		},
		getPictureUrl: (width?: number, height?: number, color?: string) =>
			parseImageMd5toUrl(
				pictureDomain,
				child.USER_PICTURE,
				width,
				height,
				color,
				'user',
			),
	};
}
