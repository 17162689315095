import type {ReadonlyURLSearchParams} from 'next/navigation';
import {
	NEXT_STEP_PARAM,
	type ContextualizedAppRedirectionConfig,
} from './config';
import {withQuery} from '@app/utils/utils';

/**
 * Given an appRedirectionConfig properly formats the redirectUri so
 * that it respects security constraints and locale management rule
 * for the target app.
 *
 * !Warning: this must be able to run in middleware.ts (edge runtime)
 * @see AppNameRedirectionConfig in redirection/utils.ts
 */
export const getRedirectUrlFromAppConfig = (
	r: string,
	appConfig: ContextualizedAppRedirectionConfig,
	locale: string,
) => {
	r = decodeURIComponent(r);
	const [redirect, query] = r.split('?');
	if (redirect.startsWith('https://')) {
		return appConfig.redirectFormatter(redirect);
	}
	const redirectUrl = new URL(appConfig.appUrl);
	const localePath = locale ? appConfig.localeFormatter(locale) : '';
	redirectUrl.pathname = `${localePath ? `/${localePath}` : ''}${redirect}`;
	if (query) {
		redirectUrl.search = query;
	}
	return redirectUrl.href;
};

export const getAsString = (param?: string[] | string) => {
	if (!param) return param;
	return Array.isArray(param) ? param[0] : param;
};

type AppendParams = {
	redirectUri: string;
	intermediateSteps?: string[];
	config: ContextualizedAppRedirectionConfig;
	query?: ReadonlyURLSearchParams | URLSearchParams;
};

/**
 * NextSteps are intermediate steps (internal only) to complete before
 * redirecting to the external redirectURI.
 *
 * This method ensures that we add them in the correct format. As always
 * it depends on the redirectAppConfig
 */
export const appendStepsToRedirectURI = ({
	config,
	query,
	redirectUri,
	intermediateSteps,
}: AppendParams): string => {
	if (!intermediateSteps?.length) return redirectUri;
	const [currentStep, ...newNextSteps] = intermediateSteps;
	const pathname = config.redirectFormatter(currentStep);
	const search = new URLSearchParams(query);
	search.delete(NEXT_STEP_PARAM);

	if (redirectUri.startsWith('/')) {
		// Internal redirect_uri might contain next_step already that we need to keep
		const [redirectPath, redirectSearchParams] = redirectUri.split('?');
		const previousSearch = new URLSearchParams(redirectSearchParams);
		const previousNextSteps = previousSearch.getAll(NEXT_STEP_PARAM);
		previousSearch.delete(NEXT_STEP_PARAM);
		previousSearch.forEach((val, key) => search.append(key, val));

		const nextSteps = [...newNextSteps, redirectPath, ...previousNextSteps];
		nextSteps.forEach((step) => search.append(NEXT_STEP_PARAM, step));
		return withQuery(pathname, search);
	} else {
		search.set(config.redirectParamName, redirectUri);
		newNextSteps.forEach((step) => search.append(NEXT_STEP_PARAM, step));
		return withQuery(pathname, search);
	}
};
