import type {AuthTokens} from '@app/hooks/auth/types';
import {buildAuthInstance} from '.';
import {setClientCookie} from '@app/utils/cookies';

type AuthInstance = ReturnType<typeof buildAuthInstance>;
let clientAuthInstance: AuthInstance | undefined;
export const getClientAuthInstance = () => {
	// Rebuilds a new instance for each users on the server or
	if (!clientAuthInstance) {
		clientAuthInstance = buildAuthInstance(false);
	}
	return clientAuthInstance;
};

/** TODO: we should not set these cookies access tokens cookies with JS
 * remove the client-side cookie management when the backend returns
 * set-cookie headers from auth calls
 * @deprecated
 */
export function setCompatibilityTokens(tokens: AuthTokens) {
	setClientCookie('arl', tokens.arl, {
		expires: 180, // 6 months
	});
	setClientCookie('jwt', tokens.jwt);
}
