import {config} from './config';

export const getCurrentUrl = (router: {
	locale: string | null;
	pathname: string;
}) => {
	let currentUrl = `${config.get('appUrl')}${config.get('basePath')}`;
	if (router.locale) {
		currentUrl += `/${router.locale}`;
	}
	currentUrl += router.pathname.split('?')[0];
	if (!currentUrl.endsWith('/')) {
		currentUrl += '/';
	}
	return currentUrl;
};
