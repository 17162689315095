import {config} from '@app/modules/config';
import {CDP} from '@deezer/logcenter';

const buildDefaultCDP = () => {
	return new CDP(config.get('segmentApiKey'));
};

let cdp: CDP;
export const getCDP = (): CDP => {
	if (!cdp) {
		cdp = buildDefaultCDP();
	}
	return cdp;
};
