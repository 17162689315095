import React from 'react';
import {VStack, type StackProps, CircularProgress, Box} from '@tempo/core';
import type {TitleDescriptionProps} from '@components/Layouts/elements';
import {
	AccountSections,
	CobrandedTopbar,
	TitleDescription,
} from '@components/Layouts/elements';
import type {LoadableLayout} from './types';

export const AuthLayout: React.FC<
	{
		children?: React.ReactNode;
		topbar?: React.ReactNode;
	} & LoadableLayout &
		Partial<TitleDescriptionProps> &
		StackProps
> = ({children, isLoading, topbar = <CobrandedTopbar />, title, detail}) => {
	return (
		<>
			<VStack
				alignItems="center"
				minH="100lvh"
				spacing="spacing.xl"
				w="100%"
				pb="spacing.3xl"
			>
				{topbar && (
					<Box as="nav" w="100%">
						{topbar}
					</Box>
				)}
				{!!title && (
					<TitleDescription detail={detail} px="spacing.l" title={title} />
				)}
				{isLoading ? (
					<CircularProgress
						isIndeterminate
						data-testid="auth-layout-loader"
						pt="spacing.3xl"
					/>
				) : (
					<AccountSections h="100%" w="100%" flexGrow="1">
						{children}
					</AccountSections>
				)}
			</VStack>
		</>
	);
};
