import React, {useEffect} from 'react';

import ThemeProvider from '@providers/ThemeProvider';
import {config} from '@modules/config';
import ApiProvider from '@deezer/react-legacy-api';
import dynamic from 'next/dynamic';
import {AppColorModeEnforcer, useColorMode} from '@tempo/core';
import type {PageProps} from '@app/types/next';
import {ConsentContextProvider} from '@tempo/cookie';
import {AppStateProvider} from '../modules/apps/context';
import {useClient} from '@app/hooks/useClient';
import {Tracking} from '@app/modules/tracking/react';
import {SEO} from './SEO';
import {customFetch} from '@app/modules/customFetch';
import {ModalController} from './Modals/Controller';
import {GlobalErrorBoundary} from './GlobalErrorBoundary';
import {useUserData} from '@app/hooks/controllers/useUserData';

const DynamicPipeProvider = dynamic(
	() => import('@app/providers/PipeProvider'),
);
const DynamicConsentScreen = dynamic(
	() =>
		import('@app/components/CookieBanner').then(
			({ConsentScreen}) => ConsentScreen,
		) as any,
	{ssr: false},
);

const AppRoot: React.FC<
	{
		children?: React.ReactNode;
	} & Omit<PageProps, '_nextI18Next'>
> = ({
	appName = 'Deezer',
	children,
	colorMode,
	isOauth,
	seo,
	template,
	useGraphQl,
}) => {
	return (
		<AppStateProvider appName={appName} isOauth={isOauth}>
			<SEO seo={seo} />
			<ThemeProvider colorMode={colorMode} template={template}>
				<GlobalErrorBoundary>
					<ApiProvider
						host={config.get('deezerApiUrl')}
						mode="cors"
						credentials="include"
						fetch={customFetch}
					>
						{colorMode ? (
							<AppColorModeEnforcer colorMode={colorMode} />
						) : (
							<UserColorModeSync />
						)}
						<ModalController>
							<ConsentContextProvider>
								{/** Quickfix to not have tracking in Zen while we
								 * haven't synced with their team to use the same
								 * analytics tools*/}
								{appName !== 'Zen' && <DynamicConsentScreen />}
								<Tracking />
								{useGraphQl ? (
									<DynamicPipeProvider>{children}</DynamicPipeProvider>
								) : (
									children
								)}
							</ConsentContextProvider>
						</ModalController>
					</ApiProvider>
				</GlobalErrorBoundary>
			</ThemeProvider>
		</AppStateProvider>
	);
};

export const UserColorModeSync: React.FC = () => {
	const {user} = useUserData();
	const {setColorMode} = useColorMode();
	const {client} = useClient();
	useEffect(() => {
		if (client) {
			setColorMode(client.options.defaultTheme);
			return;
		}
		if (!user || user.settings.isDarkModeOn) {
			setColorMode('dark');
		} else {
			setColorMode('light');
		}
	}, [client, user, setColorMode]);
	return null;
};

export default AppRoot;
