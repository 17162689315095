import Config from '@deezer/config';
import configContents from 'src/config/config.json';

const isAValidEnvName = (env: string): boolean => {
	return /^[a-z]+$/.test(env);
};

const replaceStringValueInConfig = (
	config: Record<string, unknown>,
	keys: string[],
	searchValue: string,
	replaceValue: string,
): void => {
	keys.forEach((key) => {
		if (config[key] && typeof config[key] === 'string') {
			config[key] = (config[key] as string).replace(searchValue, replaceValue);
		}
	});
};

const changeEnvFromQueryParams = (
	envDefault: string,
	config: typeof configContents,
): typeof configContents => {
	const isClientSide = typeof window !== 'undefined';

	if (!isClientSide) {
		return config;
	}

	const params = new URLSearchParams(window.location.search);
	const envParam = params.get('env');

	const updatedConfig = {...config};

	if (!(envParam && isAValidEnvName(envParam))) {
		return config;
	}

	const keysToUpdate = [
		'deezerApiUrl',
		'deezerImagesUrl',
		'deezerStaticUrl',
		'deezerWebsiteUrl',
		'uploadHost',
		'handshakeMicroserviceUrl',
		'internalDeezerApiUrl',
	];

	replaceStringValueInConfig(updatedConfig, keysToUpdate, envDefault, envParam);

	return updatedConfig;
};

export const config = new Config(
	changeEnvFromQueryParams('devtest', configContents),
);
