import React from 'react';
import Head from 'next/head';
import type {AppProps} from 'next/app';
import {appWithTranslation, type UserConfig} from 'next-i18next';
import configNextI18Next from 'next-i18next.config';
import {config} from '@modules/config';
import AppRoot from '@components/AppRoot';
import type {PageProps} from '@app/types/next';

import './global.css';

const App: React.FC<AppProps<PageProps>> = ({Component, pageProps}) => {
	React.useEffect(() => {
		if (document.domain !== 'localhost') {
			// Used for some old browsers to enable some CORS stuff
			document.domain = config.get('cookieDomain').slice(1);
		}
	}, []);

	return (
		<>
			<Head>
				<link
					rel="icon"
					type="image/x-icon"
					href={`${config.get('appAssetsUrl')}/icons/favicon.ico`}
				/>
			</Head>
			<AppRoot {...pageProps}>
				<Component {...pageProps} />
			</AppRoot>
		</>
	);
};

export default appWithTranslation(
	App,
	configNextI18Next as unknown as UserConfig,
);
