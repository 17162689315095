import {Component} from 'react';
import {AuthLayout} from './Layouts/pages/AuthLayout';
import {useTranslation} from '@app/hooks/useTranslation';
import {logCenter} from '@app/modules/tracking/logCenter';
import AuthVitalsMetric from '@app/modules/metrics/authVitals';
import {AppStateContext} from '@app/modules/apps/context';
import {getUniqId} from '@app/modules/tracking/utils';

/**
 * https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 */
export class GlobalErrorBoundary extends Component<
	React.PropsWithChildren,
	{hasError: boolean}
> {
	static contextType = AppStateContext;
	declare context: React.ContextType<typeof AppStateContext>;

	constructor(props: React.PropsWithChildren) {
		super(props);
		this.state = {hasError: false};
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return {hasError: true};
	}

	componentDidCatch(error: Error): void {
		logCenter.log(
			new AuthVitalsMetric({
				app_name: this.context.appName,
				device_uniq_id: getUniqId(),
				error: error?.message ?? error?.name ?? 'unknown_error',
				journey_type: 'common',
				error_extra: `path: ${window.location.href}`,
				server_payload: error?.stack ?? 'no stack',
			}).serialize(),
		);
	}

	render() {
		if (this.state.hasError) {
			return <ErrorUI code="CGEB1" />; // Client Global Error Boundary error 1
		}
		return this.props.children;
	}
}

export function ErrorUI({
	code,
	children,
}: React.PropsWithChildren<{code: string}>) {
	const t = useTranslation();
	return (
		<AuthLayout
			title={t('error.error')}
			detail={t('error.anerroroccurredpleasetryagain') + ` (${code})`}
		>
			{children}
		</AuthLayout>
	);
}
