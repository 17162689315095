import {
	AttributionContextLog,
	getAttributionQueryParams,
} from '@deezer/logcenter';
import {logCenter} from './logCenter';
import {getUniqId} from './utils';

/**
 * Client-side method only.
 * Uses the window.location instead of
 * useSearchParams because we don't want to react to query changes,
 * we want to log it just once with what we have at the start.
 */
export const logAttributionContext = (userID: string) => {
	const isDeezerOrEmptyReferrer =
		/deezer(|dev).com/.test(document.referrer) || document.referrer === '';
	const isMozilla = /Mozilla/.test(navigator.userAgent);
	const attributionContext = getAttributionQueryParams(
		new URLSearchParams(typeof location !== 'undefined' ? location.search : ''),
	);
	if (
		isMozilla &&
		(Object.keys(attributionContext).length > 0 || !isDeezerOrEmptyReferrer)
	) {
		logCenter.log(
			new AttributionContextLog({
				userID,
				marketingParams: attributionContext,
				dzrUniqId: getUniqId(),
			}).serialize(),
		);
	}
};
