import {isLegacyApiError} from '@app/hooks/useLegacyApiError';
import type {AuthTokens} from '../types';

export function isMissingInfoError(obj: any): obj is MissingInfoErrorResponse {
	return (
		isLegacyApiError(obj) &&
		obj.message === 'missing_info' &&
		typeof obj?.payload?.required_info !== 'undefined'
	);
}

export function isAutologDataLoggedResponse(
	obj: any,
): obj is AutologDataLoggedResponse {
	return (
		obj &&
		typeof obj === 'object' &&
		'sid' in obj &&
		'refresh_token' in obj &&
		'user_id' in obj &&
		'first_login' in obj &&
		'new_user' in obj
	);
}

export function isUnloggedError(obj: any): obj is AutologDataUnLoggedResponse {
	return isLegacyApiError(obj) && obj.name === 'USER_AUTH_ERROR';
}

export type UserAuthInfo = {
	user_id: number;
	first_login?: boolean;
	new_user?: boolean;
	checkform?: string;
};

export type AutologDataLoggedResponse = AuthTokens & UserAuthInfo;

// Todo rationnalize legacy api error types
export type AutologDataUnLoggedResponse = {
	response: {
		results: UserAuthInfo & Omit<AuthTokens, 'refresh_token' | 'arl'>;
		error: {USER_AUTH_ERROR: string};
	};
	payload: {INSTANT_AUTH?: boolean; [key: string]: unknown};
};

export type MissingInfoErrorResponse = {
	payload: {
		required_info: string[];
	} & Pick<AuthTokens, 'jwt' | 'refresh_token' | 'arl'>;
	error: {
		REQUEST_ERROR: 'missing_info';
	};
	results: Record<string, unknown>;
};

export enum AuthStatus {
	logged = 'logged',
	unlogged = 'unlogged',
	loading = 'loading',
	missinginfo = 'missinginfo',
}

export type DataAndStatus =
	| {
			data: MissingInfoErrorResponse['payload'];
			status: AuthStatus.missinginfo;
	  }
	| {
			status: AuthStatus.unlogged;
			data: AutologDataUnLoggedResponse['response']['results'];
	  }
	| {
			status: AuthStatus.logged;
			data: AutologDataLoggedResponse;
	  }
	| {
			status: AuthStatus.loading;
			data: undefined;
	  };

export function isDataAndStatus(obj: unknown): obj is DataAndStatus {
	return (
		typeof obj === 'object' &&
		obj !== null &&
		'status' in obj &&
		'data' in obj &&
		typeof obj.status === 'string' &&
		['logged', 'unlogged', 'loading', 'missinginfo'].includes(obj.status)
	);
}

export function formatData(data?: any, error?: any): DataAndStatus {
	if (data && isAutologDataLoggedResponse(data)) {
		if (data.jwt) {
			return {data, status: AuthStatus.logged};
		}
		throw new Error('Missing jwt token');
	}
	if (error) {
		if (isMissingInfoError(error)) {
			return {data: error.payload, status: AuthStatus.missinginfo};
		} else if (isUnloggedError(error)) {
			return {data: error.response.results, status: AuthStatus.unlogged};
		} else {
			throw error;
		}
	}
	return {data: undefined, status: AuthStatus.loading};
}
