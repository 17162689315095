import {useSwrApi} from '@app/modules/api/swr-legacy-api';
import {useAppState} from '@app/modules/apps/context';
import {useAppId} from '@app/modules/apps/useAppId';
import {useBrowserHash} from '../../useBrowserHash';
import {
	formatData,
	AuthStatus,
	isDataAndStatus,
	type DataAndStatus,
} from './utils';
import {useApiContext} from '@deezer/react-legacy-api';
import {setCompatibilityTokens} from '@app/modules/auth/client';
import {useCallback} from 'react';
import type {LegacyApiError} from '@app/hooks/useLegacyApiError';
import {useAuthVitalsMetrics} from '@app/hooks/metrics/useAuthVitalsMetrics';
import {useRouter} from 'next/router';

const canLogError = (error: LegacyApiError) => {
	return (
		error &&
		error?.message !== 'arl_expired' &&
		error?.message !== 'missing_info'
	);
};

export function useAutolog(authorizeUnlogged = true) {
	const {appName} = useAppState();
	const {deviceToken} = useBrowserHash();
	const {appId, isLoading} = useAppId();
	const legacyApi = useApiContext();
	const {logAuthError} = useAuthVitalsMetrics();
	const {pathname, push} = useRouter();

	const {
		data,
		mutate: mutateAutolog,
		...rest
	} = useSwrApi(
		{
			method: 'deezer_userAutolog',
			data: {
				APP_NAME: appName,
				APPLICATION_ID: appId,
				AUTHORIZE_UNLOGGED: authorizeUnlogged,
				CHECKFORM: true,
				DEVICE_TOKEN: deviceToken,
			},
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			errorRetryCount: 3,
			onSuccess: (result) => {
				if (isDataAndStatus(result) && result.status === AuthStatus.logged) {
					setCompatibilityTokens(result.data);
				}

				// redirect only for internal account urls
				if (
					isDataAndStatus(result) &&
					result.status === AuthStatus.missinginfo &&
					!document.referrer &&
					!pathname.includes('/missinginfo') &&
					!['Shaker', 'Games'].includes(appName)
				) {
					push({pathname: '/missinginfo'});
				}
			},
			// Cache key is independant of params this way we have the same cache for autolog calls even if we change the authorizeUnlogged param
			cacheKey:
				isLoading || !deviceToken
					? null
					: `deezer_userAutolog-${appName}-${appId}`,
			transform: (result, error) => {
				if (canLogError(error)) {
					logAuthError({
						e: error,
						payload: result,
						gateway_method: 'deezer_userAutolog',
						journey_type: 'common',
					});
				}

				const data = formatData(result, error);

				if (
					(data.status === AuthStatus.unlogged ||
						data.status === AuthStatus.logged) &&
					data.data.checkform
				) {
					legacyApi.setToken(data.data.checkform);
				}
				return data;
			},
		},
	);

	const mutate = useCallback(
		async (data?: DataAndStatus): Promise<DataAndStatus> => {
			if (data && data.status === AuthStatus.logged) {
				await mutateAutolog(data, {revalidate: false});
				setCompatibilityTokens(data.data);
				return data;
			}
			return mutateAutolog() as Promise<DataAndStatus>;
		},
		[mutateAutolog],
	);

	const result = data ? data : formatData(null, null);

	return {
		mutate,
		...result, // In contradiction with the regular useSWR, we always have data here
		...rest,
	};
}
