import {type StackProps, VStack, tempo} from '@tempo/core';

export const AccountSections = tempo(VStack);
AccountSections.defaultProps = {
	spacing: 'spacing.l',
};

export function AccountSection({
	children,
	withCurve,
	...stackProps
}: React.PropsWithChildren<StackProps & {withCurve?: boolean}>) {
	const bg =
		stackProps.backgroundColor ??
		stackProps.background ??
		stackProps.bg ??
		'background.neutral.primary.default';
	return (
		<VStack
			background={bg}
			position="relative"
			padding="spacing.l"
			w="100%"
			flexGrow="1"
			_before={
				withCurve
					? {
							background: bg,
							borderRadius: '100%',
							content: "''",
							h: {base: '20px', md: '40px'},
							position: 'absolute',
							top: {base: '-9px', md: '-19px'},
							w: '100%',
							zIndex: '-1',
						}
					: undefined
			}
			{...stackProps}
		>
			{children}
		</VStack>
	);
}
