/**
 * @see https://deezer.jira.com/wiki/spaces/DZP/pages/4136796191/2024Q1+account+auth+redirection+explained
 */

import type {AppName} from '@app/types/appName';
import {getLegacyLocaleFromLocale} from '@deezer/deezer-compatibility';
import {config} from '@app/modules/config';

export const NEXT_STEP_PARAM = 'next_step';

type UrlBuilderContext = {
	oauth?: {
		clientId: string;
	};
};
const WHITELIST = [
	'.deezerdev.com',
	'.deezer.com',
	'deezer.page.link', // Firebase dynamic link
	'deezer://', // Deezer deeplink
	config.get('gamesHost').replace('https://', ''),
	config.get('shakerHost').replace('https://', ''),
	config.get('zenUrl').replace('https://', ''),
];

const toPath = (p: string) => (p.startsWith('/') ? p : `/${p}`);

/**
 * For security reasons we must setup a list of trusted urls
 */
export const isURLWhitelisted = (url: string): boolean => {
	// All paths are allowed
	if (url.startsWith('/')) return true;
	if (url.startsWith('https://')) {
		for (const host of WHITELIST) {
			try {
				const urlHost = new URL(url).host;
				if (urlHost.endsWith(host)) return true;
			} catch {
				return false;
			}
		}
		return false;
	}
	return false;
};

export type AppConfig = {
	redirectParamName: 'redirect_uri' | 'path';
	getAuthPaths: (
		ctx: UrlBuilderContext,
	) => Record<
		'forgotPassword' | 'login' | 'signup' | 'subProfilePicker' | 'missinginfo',
		`/${string}`
	>;
	appUrl: string;
	family?: {
		shouldSelectSubProfile?: boolean;
	};
	localeFormatter: (l: string) => string | undefined;
	redirectFormatter: (redirect: string, isOauth?: boolean) => string;
};

/**
 * This config holds all the required fields to build all redirect urls for a given AppName as
 * well as to create all redirection utils that vary depending on the app context.
 *
 * This helps to centralize the per-app specificities in this scoped config instead of having
 * if / else in the codebase. Removing these if / else in consumer components also makes the
 * e2e behavior more predictible, improving the reliability of unit testing the code of these
 * redirection.
 *
 * This config should not be used as is, you have 2 utils to consume it:
 * - getAppRedirectionConfig(ctx): a predictible that given a context yields the correct appConfig
 * - useAppRedirectionConfig(): automatically understands the context to provide the correct appConfig
 */
export const AppNameRedirectionConfig: Record<AppName, AppConfig> = {
	Deezer: {
		appUrl: config.get('deezerWebsiteUrl'),
		localeFormatter: getLegacyLocaleFromLocale,
		getAuthPaths(ctx) {
			if (ctx.oauth) {
				return {
					forgotPassword: `/oauth/forgot-password/${ctx.oauth.clientId ?? ''}`,
					login: `/oauth/login/${ctx.oauth.clientId ?? ''}`,
					signup: `/oauth/signup/${ctx.oauth.clientId ?? ''}`,
					subProfilePicker: `/oauth/members/picker/${ctx.oauth.clientId ?? ''}`,
					missinginfo: '/404',
				};
			}
			return {
				forgotPassword: '/forgot-password',
				login: '/login',
				signup: '/signup',
				subProfilePicker: '/members/picker',
				missinginfo: '/missinginfo',
			};
		},
		redirectParamName: 'redirect_uri',
		family: {
			shouldSelectSubProfile: true,
		},
		redirectFormatter: (redirect, isOauth = false) => {
			// No checks on oauth urls for now
			return isOauth || isURLWhitelisted(redirect)
				? redirect
				: `${config.get('deezerWebsiteUrl')}/`;
		},
	},
	Zen: {
		appUrl: config.get('zenUrl'),
		localeFormatter: () => undefined,
		getAuthPaths() {
			return {
				forgotPassword: '/zen/login-without-password',
				login: '/zen/login',
				signup: '/zen/signup',
				subProfilePicker: '/404', // Disabled feature,
				missinginfo: '/missinginfo',
			};
		},
		redirectParamName: 'redirect_uri',
		redirectFormatter: (redirect) => {
			return isURLWhitelisted(redirect) ? redirect : `${config.get('zenUrl')}/`;
		},
	},
	Games: {
		appUrl: config.get('gamesHost'),
		localeFormatter: () => undefined,
		getAuthPaths() {
			return {
				forgotPassword: '/404', // Verify password only
				login: '/games',
				signup: '/games',
				subProfilePicker: '/404', // Disabled feature
				account: '/games/account',
				missinginfo: '/404',
			};
		},
		redirectParamName: 'path',
		redirectFormatter: toPath,
	},
	Shaker: {
		appUrl: config.get('shakerHost'),
		localeFormatter: () => undefined,
		getAuthPaths() {
			return {
				forgotPassword: '/404', // Verify password only
				login: '/shaker',
				signup: '/shaker',
				subProfilePicker: '/404', // Disabled feature,
				account: '/shaker/account',
				missinginfo: '/404',
			};
		},
		redirectParamName: 'path',
		redirectFormatter: toPath,
	},
};

export type ContextualizedAppRedirectionConfig = Omit<
	AppConfig,
	'getAuthPaths' | 'redirectFormatter'
> & {
	getAuthPaths: () => ReturnType<AppConfig['getAuthPaths']>;
	redirectFormatter: (
		redirect: string,
	) => ReturnType<AppConfig['redirectFormatter']>;
};

/**
 * Method that provides the correct redirectionAppConfig depending a given context
 */
export const getAppRedirectionConfig = (ctx: {
	appName: AppName;
	oauth?: {
		clientId: string;
	};
	locale?: string;
}): ContextualizedAppRedirectionConfig => {
	const baseConfig = AppNameRedirectionConfig[ctx.appName];
	return {
		...baseConfig,
		redirectFormatter: (r) => baseConfig.redirectFormatter(r, !!ctx.oauth),
		getAuthPaths: () => baseConfig.getAuthPaths(ctx),
	};
};
