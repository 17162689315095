import {config} from '@app/modules/config';
import Cookies, {type CookieAttributes} from 'js-cookie';

export function setClientCookie(
	name: string,
	value: string,
	options?: CookieAttributes,
) {
	Cookies.set(name, value, {
		domain: config.get('cookieDomain'),
		secure: true,
		...options,
	});
}

export function removeClientCookie(name: string, options?: CookieAttributes) {
	Cookies.remove(name, {
		domain: config.get('cookieDomain'),
		...options,
	});
}

export function getClientCookie(name: string) {
	return Cookies.get(name);
}
