import type {ModalProps} from '@tempo/core';
import type {ModalKey, ModalPropsMapping} from './List';
import {createContext} from '@deezer/react-utils';

export type ModalControllerProps = {
	current: string | null;
	openModal: <M extends ModalKey>(
		modal: M,
		props?: ModalPropsMapping[M] | null,
		options?: Partial<ModalProps>,
	) => void;
	closeModal: () => void;
};

export type ModalState = {
	current: ModalKey | null;
	modalOptions: Partial<ModalProps>;
	props: ModalPropsMapping[ModalKey] | null;
};

const [ModalControllerProvider, useModalController] =
	createContext<ModalControllerProps>({name: 'ModalController'});
export {useModalController, ModalControllerProvider};
