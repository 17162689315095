export const capitalizeFirstLetter = (str: string): string => {
	if (!str) return '';
	if (str.length === 0) {
		return str;
	}

	const upperCaseFirstLetter = str.charAt(0).toUpperCase();

	return upperCaseFirstLetter + str.toLowerCase().slice(1);
};

export function safeStringify(obj: any) {
	try {
		return JSON.stringify(obj);
	} catch (error) {
		return 'null';
	}
}
