import {usePrevious} from '@deezer/react-utils';
import {useEffect} from 'react';
import {getCDP} from '.';
import {cdpStrategy} from '../logCenter';
import {useUserData} from '@app/hooks/controllers/useUserData';
import {useUserConsent} from '@tempo/cookie';
import type {TraitEvent} from '@deezer/logcenter/build/types/Sender/CDPSender/types';
import {getUniqId} from '../utils';
import {useAutolog} from '@app/hooks/auth/useAutolog';

export function useUserIdentifyProfile(): TraitEvent | null {
	const {user} = useUserData();
	const {data} = useAutolog();
	const {consentSettings} = useUserConsent();

	if (!consentSettings) return null;

	const profile: TraitEvent = {
		statistics: !!consentSettings.statistics,
		targetedAds: !!consentSettings.targetedAdvertising,
		deezer_unique_id: getUniqId(),
		userId: (data as any)?.user_id?.toString(),
	};
	if (user?.email) {
		profile.email = user?.email;
	}
	return profile;
}

/**
 * @deprecated - For init use only, prefer using the one from @app/modules/tracking instead
 */
export const useInitCDP = () => {
	const profile = useUserIdentifyProfile();
	const previousProfile = usePrevious(profile);

	useEffect(() => {
		if (!profile) return;

		// First load
		if (!previousProfile) {
			getCDP().init();
			getCDP().identify(profile);
			cdpStrategy.openGate();
		}
	}, [profile, previousProfile]);
	return Boolean(profile);
};
