import {logCenter} from '../logCenter';
import type {Trackables} from './types';

/** Ugly side-effect but it works for now.. */
let pageName = '';

/**
 * Remove me once GTM is deprecated and migrated to CDP
 */
export const logGTM = (
	payload: Partial<Trackables> & Record<string, string | number | boolean>,
	/** Bypass gate strategy to directly push events to the dataLayer */
	force = false,
) => {
	if (payload.pagename) {
		pageName = payload.pagename;
	}
	if (force && 'dataLayer' in window && Array.isArray(window.dataLayer)) {
		return window.dataLayer.push(payload);
	}
	logCenter.log({
		type: 'gtm',
		eventName: payload.event,
		customAttributes: {
			eventlabel: '',
			...payload,
		},
	});
};

export const logClick = (
	payload: Partial<Trackables> & Record<string, string | number | boolean>,
) => {
	logGTM({
		event: 'click',
		eventcategory: pageName, // Context-less retrocompatibility with react-tracking
		...payload,
	});
};
