const path = require('path');
const {initReactI18next} = require('react-i18next');
const sprintf = require('i18next-sprintf-postprocessor');
const {availableLanguages} = require('./locales/metas.json');

module.exports = {
	i18n: {
		defaultLocale: 'default',
		locales: [
			'default',
			'en',
			...availableLanguages.filter((lang) => lang !== 'en'),
		],
		localeDetection: false,
	},
	compatibilityJSON: 'v3',
	localePath: path.resolve('./locales'),
	localeStructure: '{{lng}}/{{ns}}',
	lowerCaseLng: true,
	nsSeparator: ':::',
	keySeparator: '::',
	postProcess: 'sprintf',
	use: [initReactI18next, sprintf],
	serializeConfig: false,
};
