import {useSwrApi} from '@app/modules/api/swr-legacy-api';
import {useAppState} from '@app/modules/apps/context';
import {useAutolog} from '@app/hooks/auth/useAutolog';
import {AuthStatus, isMissingInfoError} from '@app/hooks/auth/useAutolog/utils';
import {transformLegacyUser} from '@app/utils/userData';
import {safeStringify} from '@app/utils/string';
import {useAuthVitalsMetrics} from '../metrics/useAuthVitalsMetrics';

export function useUserData() {
	const {appName} = useAppState();
	const {status, data: autologData} = useAutolog();
	const {logAuthError} = useAuthVitalsMetrics();

	const {data, mutate, ...rest} = useSwrApi(
		{
			method: 'deezer.getUserData',
			data: {
				APP_NAME: appName,
			},
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			onErrorRetry(err, _, __, revalidate, {retryCount}) {
				if (retryCount >= 3 || isMissingInfoError(err)) {
					return;
				}
				setTimeout(() => revalidate({retryCount}), retryCount * 1000);
			},
			cacheKey:
				status === AuthStatus.loading || status !== AuthStatus.logged
					? null
					: `${appName}-user-data`,
			transform: (result, error) => {
				if (
					String(result.USER.USER_ID) !== String((autologData as any)?.user_id)
				) {
					logAuthError({
						journey_type: 'common',
						e: {message: 'user_id_mismatch', name: 'Error'},
						server_payload: safeStringify(error),
						error_extra: 'mismatch between autolog and getUserData output',
						gateway_method: 'deezer.getUserData',
					});
				}
				if (error) {
					throw error;
				}
				return transformLegacyUser(result);
			},
		},
	);

	return {
		user: data,
		refetchUser: mutate,
		...rest,
	};
}
