import {useAppState} from '@app/modules/apps/context';
import {useSwrApi} from '@app/modules/api/swr-legacy-api';
import type {ThirdPartyType} from '@deezer/legacy-auth';
import {useAuthVitalsMetrics} from '../metrics/useAuthVitalsMetrics';
import {useRouter} from 'next/router';
import {useAutolog} from '../auth/useAutolog';
import {AuthStatus} from './useAutolog/utils';

export type Intent = 'login' | 'register';
export type Field = 'username' | 'age' | 'identity' | 'email' | 'password';
type DataObject = {[key: string]: unknown};

export type JourneyConfigType<
	T extends string = string,
	D extends DataObject = DataObject,
> = {
	template: T;
	data: D & {
		socials: ThirdPartyType[];
		socials_with_disabled: ThirdPartyType[];
		switch_to_phone_button?: boolean;
		default_country?: {
			label: string;
			country_iso: string;
			phone_code: string;
		};
	};
};

const isJourney = (obj: any): obj is JourneyConfigType =>
	!!obj?.template && !!obj?.data?.socials;

export type EmailRegJourney = JourneyConfigType<
	'email_register',
	{
		register_needs_consent_transfer_data_and_privacy_policy: boolean;
		fields: Field[];
		non_binary_allowed: boolean;
		socials: ThirdPartyType[];
		socials_with_disabled: ThirdPartyType[];
	}
>;
export const isEmailRegJourney = (obj: any): obj is EmailRegJourney =>
	isJourney(obj) && obj.template === 'email_register' && !!obj.data.fields;

export type LoginJourney = JourneyConfigType<'email_login'>;
export const isEmailLoginJourney = (obj: any): obj is LoginJourney =>
	isJourney(obj) && obj.template === 'email_login' && !obj.data.default_country;

export type PhoneLoginJourney = JourneyConfigType<'phone_login'>;
export const isPhoneLoginJourney = (obj: any): obj is LoginJourney =>
	isJourney(obj) && obj.template === 'phone_login';

export type SmartJourneyStep = keyof SmartJourney['data']['steps_config'];
export type SmartJourney = {
	template: 'smart_v2';
	data: {
		steps_config: {
			email_or_phone?: {
				title: string;
				switch_to_phone_button: boolean;
				input_placeholder: string;
				phone_activated: boolean;
				country_placeholder?: string;
				default_country?: {
					label: string;
					country_iso: string;
					phone_code: string;
				};
				fallback_country_closed_for_phone?: {
					title: string;
					input_placeholder: string;
					phone_code: boolean;
				};
				socials: ThirdPartyType[];
				socials_with_disabled: ThirdPartyType[];
			};
			user_profile?: {
				privacy_transfer_consent: boolean;
				non_binary_allowed: boolean;
				fields: Field[];
			};
			username_and_picture?: {
				privacy_transfer_consent: boolean;
			};
			create_password?: {
				password_policy: string;
			};
		};
		registration_info_steps: Array<SmartJourneyStep>;
	};
};
export const isSmartJourney = (obj: any): obj is SmartJourney => {
	return obj.template === 'smart_v2' && !!obj.data.steps_config;
};

export type AuthConfig = {
	version: 'unlogged_web_v1' | 'unlogged_smart_web_incomplete_v1' | string;
	journeys: {
		smart?: SmartJourney;
		login?: LoginJourney;
		phone_login?: LoginJourney;
		register?: EmailRegJourney;
	};
	default_journeys: Record<Intent, keyof AuthConfig['journeys']>;
};

const isAuthJourney = (obj: any): obj is AuthConfig => {
	return !!obj && !!obj.version && !!obj.journeys && !!obj.default_journeys;
};

const loadAuthConfigErrorPayloadLog = {
	e: {
		name: 'auth-vitals-load-error',
		message: 'an error occured while loading the auth-config',
	},
	journey_type: 'common',
	gateway_method: 'deezer.authConfig',
} as const;

let retryCount = 0;
const MAX_RETRY_BEFORE_LOG = 3;

export const useAuthConfig = () => {
	const {status} = useAutolog();
	const {appName} = useAppState();
	const {logAuthError} = useAuthVitalsMetrics();
	const router = useRouter();
	const isPartner = router.query.template === 'activation_journey';
	const {data, error} = useSwrApi(
		status === AuthStatus.loading
			? null
			: {
					method: 'deezer.authConfig',
					data: {APP_NAME: appName, PARTNER: isPartner},
				},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			onError: (err) => {
				retryCount++;
				if (retryCount === MAX_RETRY_BEFORE_LOG) {
					logAuthError({...loadAuthConfigErrorPayloadLog, payload: err});
					return;
				}
			},
			onSuccess: (data) => {
				try {
					if (!data || Object.keys(data).length === 0 || !isAuthJourney(data)) {
						throw new Error('Empty auth config');
					}
					retryCount = 0;
				} catch {
					// Empty auth config or one of the evaluation conditions above failed
					logAuthError({
						...loadAuthConfigErrorPayloadLog,
						payload: {reason: 'empty auth config'},
					});
				}
			},
		},
	);

	if (
		error ||
		!data ||
		Object.keys(data).length === 0 ||
		!isAuthJourney(data)
	) {
		return {data: undefined, error};
	}
	return {data, error};
};
