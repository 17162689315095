import React from 'react';
import type {HeadingProps} from '@tempo/core';
import {type StackProps, Text, VStack} from '@tempo/core';
import {LandingHeading} from '@tempo/landing';

export function highlightText(text: string): string {
	return `<span class="highlight">${text}</span>`;
}

export type TitleDescriptionProps = {
	title: string;
	detail?: React.ReactNode;
	titleAs?: HeadingProps['as'];
} & StackProps;
export const TitleDescription: React.FC<TitleDescriptionProps> = ({
	title,
	detail,
	titleAs = 'h1',
	children,
	...stackProps
}) => {
	const description: React.ReactNode = detail ?? children;
	return (
		<VStack
			px="spacing.l"
			spacing="spacing.s"
			textAlign="center"
			w="100%"
			{...stackProps}
		>
			<LandingHeading as={titleAs} data-testid="page-header-text">
				{title}
			</LandingHeading>
			{description && typeof description === 'string' ? (
				<Text
					variant="body.l.default"
					color="text.neutral.secondary.default"
					sx={{
						'> .highlight': {
							color: 'text.neutral.primary.default',
						},
					}}
					dangerouslySetInnerHTML={{__html: description}}
				/>
			) : (
				description
			)}
		</VStack>
	);
};
