import React from 'react';
import {parseImageMd5toUrl} from '@app/utils/images';
import type {ImageProps} from '@tempo/core';
import {Image, useColorMode} from '@tempo/core';
import type {Client} from '@app/modules/oauth/types';
import {config} from '@app/modules/config';

export const ClientLogo: React.FC<
	{client?: Client} & Omit<ImageProps, 'src' | 'alt'>
> = ({client, ...imgProps}) => {
	const {colorMode} = useColorMode();
	return (
		<Image
			src={parseImageMd5toUrl(
				config.get('deezerImagesUrl'),
				colorMode === 'light'
					? (client?.options.logoThemeLightUrl ?? '')
					: (client?.options.logoThemeDarkUrl ?? ''),
			)}
			alt={client?.clientName}
			height="9"
			width="9"
			data-testid="partner-logo"
			{...imgProps}
		/>
	);
};
