import type {PageConfig} from '@app/types/next';

export const getAppNameFromPathUrl = (
	path: string,
): Required<PageConfig['appName']> => {
	const parts = path.split('/');

	if (parts.includes('shaker')) {
		return 'Shaker';
	}

	if (parts.includes('games')) {
		return 'Games';
	}

	if (parts.includes('zen')) {
		return 'Zen';
	}

	return 'Deezer';
};

export const getIsOauthFromPath = (path: string): boolean =>
	!!path && path.startsWith('/oauth/');
