import type {PageConfig} from '@app/types/next';
import {createContext} from '@deezer/react-utils';
import {getIsOauthFromPath} from './utils';
import {usePathname} from 'next/navigation';

export type AppState = {
	appName: PageConfig['appName'];
	isOauth: boolean;
};
const [AppStateContextProvider, useAppState, AppStateContext] =
	createContext<AppState>({
		name: 'AppState',
	});

export type AppStateProviderProps = {
	appName: AppState['appName'];
	isOauth?: AppState['isOauth'];
};
const AppStateProvider: React.FC<
	React.PropsWithChildren<AppStateProviderProps>
> = ({appName, isOauth, children}) => {
	const pathname = usePathname();
	return (
		<AppStateContextProvider
			value={{
				isOauth: isOauth ?? getIsOauthFromPath(pathname),
				appName,
			}}
		>
			{children}
		</AppStateContextProvider>
	);
};

export {AppStateProvider, useAppState, AppStateContext};
