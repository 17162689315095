import {useEffect, useState} from 'react';

async function generateBrowserHash() {
	const uniqueData: string[] = [];

	// Collect MIME types data as a replacement for plugins
	for (let i = 0; i < navigator.mimeTypes.length; i++) {
		uniqueData.push(navigator.mimeTypes[i].type);
		uniqueData.push(navigator.mimeTypes[i].description);
	}

	// Collect user agent
	uniqueData.push(navigator.userAgent);

	// Collect screen properties
	uniqueData.push(screen.availHeight.toString());
	uniqueData.push(screen.availWidth.toString());
	uniqueData.push(screen.colorDepth.toString());
	uniqueData.push(screen.height.toString());
	uniqueData.push(screen.pixelDepth.toString());
	uniqueData.push(screen.width.toString());

	// Collect WebGL info
	try {
		const canvas = document.createElement('canvas');
		const gl = (canvas.getContext('webgl') ||
			canvas.getContext('experimental-webgl')) as WebGLRenderingContext | null;

		if (gl) {
			uniqueData.push(gl.getParameter(gl.VERSION) as string);
			uniqueData.push(gl.getParameter(gl.SHADING_LANGUAGE_VERSION) as string);
			uniqueData.push(gl.getParameter(gl.VENDOR) as string);
			uniqueData.push(gl.getParameter(gl.RENDERER) as string);
			const extensions = gl.getSupportedExtensions();
			if (extensions) {
				uniqueData.push(extensions.join());
			}
		}
	} catch (e: any) {
		uniqueData.push(e?.toString());
	}

	let storedID = localStorage.getItem('browserID');
	if (!storedID) {
		const randomBytes = crypto.getRandomValues(new Uint8Array(10));
		storedID = Array.from(randomBytes)
			.map((b) => b.toString(16))
			.join('');
		localStorage.setItem('browserID', storedID);
	}
	uniqueData.push(storedID);

	// Use crypto.subtle.digest for hashing
	const encoder = new TextEncoder();
	const data = encoder.encode(uniqueData.join());
	const hashBuffer = await crypto.subtle.digest('SHA-256', data);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashedValue = hashArray
		.map((b) => b.toString(16).padStart(2, '0'))
		.join('');
	return hashedValue;
}

let tokenGenerationPromise: Promise<string> | undefined;
export function useBrowserHash() {
	const [deviceToken, setDeviceToken] = useState<string>();

	useEffect(() => {
		if (!tokenGenerationPromise) {
			tokenGenerationPromise = generateBrowserHash();
		}
		tokenGenerationPromise.then((token) => {
			setDeviceToken(token);
		});
	}, []);

	return {deviceToken};
}
